import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import FAQBox from "components/FAQs/IN-6012HD/faqBox";
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "FAQ :: IN-6012 HD",
  "path": "/FAQs/IN-6012HD/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "HD network camera that you can connect to your existing network via Wifi or LAN. The camera supports H264 video compression, SD card recording during alarm and on, video upload via FTP, email notification with images attached, pan & tilt control via your PC / MAC or smartphone. Free DDNS, P2P address that allows you to access from anywhere in the world.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const NavButtons = makeShortcode("NavButtons");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQ :: IN-6012 HD' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='HD network camera that you can connect to your existing network via Wifi or LAN. The camera supports H264 video compression, SD card recording during alarm and on, video upload via FTP, email notification with images attached, pan & tilt control via your PC / MAC or smartphone. Free DDNS, P2P address that allows you to access from anywhere in the world.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/FAQs/IN-6012HD/' locationFR='/fr/FAQs/IN-6012HD/' crumbLabel="IN-6012" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "frequently-asked-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#frequently-asked-questions",
        "aria-label": "frequently asked questions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frequently Asked Questions`}</h1>
    <h2 {...{
      "id": "in-6012-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-6012-hd",
        "aria-label": "in 6012 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-6012 HD`}</h2>
    <FAQBox mdxType="FAQBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      